<template>
  <div class="ticket" :class="{ notAssigned: !ticket.assigned_users.length }">
    <div ref="tcktinfo" class="info">
      <div class="item day">
        <span class="day">
          {{
            internalInstance.appContext.config.globalProperties
              .$moment(ticket.ticket_status.created_at)
              .format("MMMM D, YYYY")
          }}
        </span>
        <span class="hour">
          {{
            internalInstance.appContext.config.globalProperties
              .$moment(ticket.ticket_status.created_at)
              .format("h:mm a")
          }}
        </span>
      </div>
      <div class="item day">
        <span class="day">
          {{
            internalInstance.appContext.config.globalProperties
              .$moment(ticket.ticket_status.updated_at)
              .format("MMMM D, YYYY")
          }}
        </span>
        <span class="hour">
          {{
            internalInstance.appContext.config.globalProperties
              .$moment(ticket.ticket_status.updated_at)
              .format("h:mm a")
          }}
        </span>
      </div>
      <div class="item large" @click="goToTicket">
        <div class="tkt-info">
          <div v-if="ticket.ticket_raised.avatar" class="img">
            <img :src="imgURL + ticket.ticket_raised.avatar" alt="" />
          </div>
          <span v-else class="no-img">{{
            ticket.ticket_raised.name.charAt(0)
          }}</span>
          <div class="desc">
            <div class="name">{{ ticket.title }} #{{ ticket.id }}</div>
            <div class="user">
              <span class="usr-name">{{ ticket.ticket_raised.name }}</span>
              <span class="time"> • created {{ timeSince }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <template v-if="ticket.client">{{ ticket.client.name }}</template>
        <template v-else>undefined</template>
      </div>
      <template v-if="ticket.product">
        <div class="item">{{ ticket.product.name }}</div>
      </template>
      <template v-else>
        <div class="item">unknown product</div>
      </template>
      <div class="item assign">
        <div class="assigned" ref="usr-avt">
          <template v-if="!ticket.assigned_users.length"> unassigned </template>
          <template v-else-if="ticket.assigned_users.length === 1">
            <div v-if="ticket.assigned_users[0].avatar" class="img">
              <img :src="imgURL + ticket.assigned_users[0].avatar" alt="" />
            </div>
            <span v-else class="no-img">{{ ticket.assigned_users[0].name.charAt(0) }}</span>
            <div class="user">
              <span class="usr-name">{{ ticket.assigned_users[0].name }}</span>
              <span class="designation">{{ ticket.assigned_users[0].role_type }}</span>
            </div>
          </template>
          <template
            v-else
            v-for="(user, index) in ticket.assigned_users"
            :key="index"
          >
            <template v-if="index < 4">
              <div class="user-avatar" :class="{ more: index === 3 }" :title="user.name">
                <template v-if="index < 3">
                  <img v-if="user.avatar" :src="imgURL + user.avatar" alt="" />
                  <span v-else>{{ user.name.charAt(0) }}</span>
                </template>
                <template v-if="index === 3">
                  <span class="more"
                    >+{{ ticket.assigned_users.length - 3 }}</span
                  >
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="item priority">
        <div class="current">
          <div class="prio">
            <img :src="priority.icon" alt="" />
            <span :class="priority.name">{{ priority.name }}</span>
          </div>
        </div>
        <transition name="fadeHeight" mode="out-in">
          <div
            v-if="openPriorities"
            ref="prios"
            class="toChoose"
            :class="{ openPriority: !closePrios, closePriority: closePrios }"
          >
            <div
              class="priority-option"
              v-for="(prio, index) in priorities"
              :key="index"
              @click="handlePrio(prio)"
            >
              <img :src="prio.icon" />
              <span :class="prio.name">{{ prio.name }}</span>
              <img
                class="arrow"
                v-if="prio.id === undefined"
                src="/assets/images/left-arrow-grey.svg"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="options" @click="edit = !edit">
      <img src="/assets/images/black_dots.svg" alt="" />
      <options-modal
        v-if="edit"
        :options="['re-open ticket']"
        :noDel="true"
        @re-openticket="handleReopen"
      />
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import { getCurrentInstance } from "vue";
import optionsModal from "@/components/Ui/General/Options.vue";
import { mapActions } from "vuex";
import {
  SEARCH_USERS_BY_KEY,
  UPDATE_TICKET_USERS_ASSIGNMENT_ACTION,
  CHANGE_TICKET_PRIORITY_ACTION,
} from "@/store/storeconstants";
export default {
  props: {
    ticket: {
      required: false,
      type: Object,
    },
  },
  components: {
    optionsModal,
  },
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      toAssign: false,
      userSrch: "",
      searchResult: [],
      openPriorities: false,
      priorities: [
        {
          id: undefined,
          icon: "/assets/images/nan_flag.svg",
          name: "chose priority",
        },
        {
          id: 1,
          icon: "/assets/images/low_flag.svg",
          name: "low",
        },
        {
          id: 2,
          icon: "/assets/images/medium_flag.svg",
          name: "medium",
        },
        {
          id: 3,
          icon: "/assets/images/high_flag.svg",
          name: "high",
        },
      ],
      selectedPriority: {},
      selectedAssignment: {},
      closePrios: false,
      internalInstance: getCurrentInstance(),
    };
  },

  watch: {
    ticket() {
      if (this.$refs["usr-avt"]) {
        const avatars = Array.from(this.$refs["usr-avt"].children).filter(
          (el) => el.classList.contains("user-avatar")
        );
        if (avatars.length) {
          let value = avatars.length;
          avatars.forEach((element) => {
            element.style.zIndex = value;
            value -= 1;
          });
        }
      }
    },
  },
  computed: {
    timeSince() {
      const last_updated = Date.parse(this.ticket.updated_at);
      const seconds = Math.floor((new Date() - last_updated) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return `at ${this.ticket.updated_at.split("T")[0]}`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return `at ${this.ticket.updated_at.split("T")[0]}`;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        if (Math.floor(interval) > 5)
          return `at ${this.ticket.updated_at.split("T")[0]}`;
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "day ago" : "days ago"
        }`;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "hour ago" : "hours ago"
        }`;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "minute ago" : "minutes ago"
        }`;
      }
      return `${Math.floor(seconds)} ${
        Math.floor(seconds) === 1 ? "second ago" : "seconds ago"
      }`;
    },
    priority() {
      let icon;
      let name;
      let id = this.ticket.ticket_priority.priority_details.id;

      switch (this.ticket.ticket_priority.priority_details.id) {
        case 1:
          icon = "/assets/images/low_flag.svg";
          name = "low";
          break;
        case 2:
          icon = "/assets/images/medium_flag.svg";
          name = "medium";
          break;
        case 3:
          icon = "/assets/images/high_flag.svg";
          name = "high";
          break;

        default:
          icon = "/assets/images/nan_flag.svg";
          name = "set priority";
          break;
      }

      return { id, icon, name };
    },
  },
  methods: {
    ...mapActions("user", {
      getUsersBySearch: SEARCH_USERS_BY_KEY,
    }),

    ...mapActions("ticket", {
      updateUserAssignment: UPDATE_TICKET_USERS_ASSIGNMENT_ACTION,
      changeTicketPriority: CHANGE_TICKET_PRIORITY_ACTION,
    }),

    goToTicket() {
      this.$router.push({ path: `/remote-support/history/${this.ticket.id}` });
    },
    async handleReopen() {
      const data = {
        ticket_id: this.ticket.id,
        status_id: 3,
      };
      const response = await axiosInstance.post(`change-ticket-status`, data);

      if (response) this.$router.push("/remote-support/tickets");
    },
  },
  mounted() {
    if (this.$refs["usr-avt"]) {
      const avatars = Array.from(this.$refs["usr-avt"].children).filter((el) =>
        el.classList.contains("user-avatar")
      );
      if (avatars.length) {
        let value = avatars.length;
        avatars.forEach((element) => {
          element.style.zIndex = value;
          value -= 1;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.overlay {
  width: 100%;
  height: 80px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #f2f5f8;

  .bar {
    width: 15%;
    height: 30px;
    background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
    background-size: 400% 400%;
    animation: load 1s ease infinite;
    border-radius: 30px;

    &.large {
      width: 20%;
      background: none;
      animation: unset;
      border-radius: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      .round {
        width: 30px;
        height: 30px;
        background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
        background-size: 400% 400%;
        animation: load 0.5s ease infinite;
        border-radius: 50%;
      }

      .txt {
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 2px;

        .top {
          height: 17px;
        }
        .bottom {
          height: 11px;
        }
        div {
          background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
          background-size: 400% 400%;
          animation: load 0.5s ease infinite;
          border-radius: 30px;
        }
      }
    }
  }
}
.ticket {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 25px;
  background: white;
  border-bottom: solid 1px #f2f5f8;
  cursor: pointer;

  &.notAssigned {
    background: $grey-opacity;
  }

  .info {
    width: 98%;
    display: flex;
    .item {
      width: calc(93% / 6);
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      
      &:nth-child(3) {
        width: 30%;
      }

      &.day {
        flex-direction: column;
        justify-content: center;
        align-items: unset;
        .day {
          font-weight: 400;
          font-size: 0.7rem;
        }

        .hour {
          font-size: 0.8rem;
        }
      }

      .tkt-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        .img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .no-img {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          background: var(--primary-color);
        }

        .desc {
          display: flex;
          flex-direction: column;

          .name {
            &:first-letter {
              text-transform: uppercase;
            }
          }

          .user {
            font-size: 0.7rem;
            .usr-name {
              font-weight: 500;
            }

            .time {
              color: #cad6e2;
              font-weight: 400;
            }
          }
        }
      }

      &.assign {
        .assigned {
          display: flex;
          align-items: center;
          gap: 10px;

          .user-avatar {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            color: white;
            overflow: hidden;
            border: solid 2px #f2f5f8;
            margin-left: -25px;
            background: var(--primary-color);
            display: flex;
            justify-content: center;
            align-items: center;
            &.more {
              color: black;
              background: #f2f5f8;
              font-size: 0.7rem;
              margin-left: -20px;
            }
            &:first-of-type {
              margin-left: unset;
            }

            img {
              min-width: 100%;
              min-height: 100%;
              object-fit: cover;
            }
          }
          .no-img {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: white;
            background: var(--primary-color);
          }
          .img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            img {
              height: 100%;
              object-fit: cover;
            }
          }
          .user {
            display: flex;
            flex-direction: column;
            .designation {
              text-transform: uppercase;
              font-weight: 500;
              font-size: 0.6rem;
            }
          }
        }
      }
      &.priority {
        text-transform: capitalize;
        font-weight: 400;
        position: relative;
        display: flex;
        .current {
          display: flex;
          gap: 5px;
          .prio {
            display: flex;
            gap: 5px;
          }
        }
        span {
          color: #cad6e2;
        }
        .medium {
          color: #fb9a4b;
        }
        .high {
          color: #ed0423;
        }
      }
    }
  }

  .options {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    user-select: none;

    .dropdown-options {
      top: 60%;
    }
  }
}
</style>
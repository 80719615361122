<template>
    <div class="details-wrapper">
        <div class="image" v-if="ticket.image">
            <img :src="ticket.image" alt="">
        </div>
        <div class="info">
            <div class="title">{{ticket.product.name}} #{{ticket.product.serial_no}}</div>
            <div class="more">
                <div class="item">
                    <span class="sub">Client</span>
                    <span class="desc" v-if="ticket.product.client">{{ticket.product.client.name}}</span>
                    <span class="desc" v-else>No Client Defined</span>
                </div>
                <div class="item">
                    <span class="sub">Site</span>
                    <span class="desc" v-if="ticket.project">{{ticket.project.name}}</span>
                    <span class="desc" v-else>No Site Defined</span>
                </div>
                <div class="item">
                    <span class="sub">Warranty</span>
                    <span class="desc" v-if="ticket.product.warranty_end_date">Expires on <br/> {{internalInstance.appContext.config.globalProperties
                    .$moment(ticket.product.warranty_end_date)
                    .format("MMMM D, YYYY")}} </span>
                    <span class="desc" v-else>Wasn't yet defined for this product</span>
                </div>
                <div class="item">
                    <span class="sub">Maintenance</span>
                    <span class="desc" v-if="ticket.product.maintenance">Valid Until <br/> {{internalInstance.appContext.config.globalProperties
                    .$moment(ticket.product.maintenance)
                    .format("MMMM D, YYYY")}}</span>
                    <span class="desc" v-else>Wasn't yet defined for this product</span>
                </div>
                <div class="item">
                    <span class="sub">Ref</span>
                    <span class="desc" v-if="ticket.product.ref || ticket.product.serial_no || ticket.product.product_number">{{ticket.product.ref || ticket.product.serial_no || ticket.product.product_number}}</span>
                    <span class="desc" v-else>Wasn't yet defined for this product</span>
                </div>
                <div class="item description">
                    <span class="sub">Ticket Description Provided</span>
                    <span class="desc" v-if="ticket.description">{{ticket.description}}</span>
                    <span class="desc" v-else>No description was provided</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance } from "vue";
export default {
    props: {
        ticket: {
            required: false,
            type: Object,
        },
    },
    data() {
      return {
        internalInstance: getCurrentInstance()
      };
    },
    
}
</script>

<style lang="scss" scoped>
    .details-wrapper {
        width: 70%;
        background: #F2F5F8;
        border-radius: 5px;
        display: flex;
        gap: 20px;
        padding: 40px 20px;
        height: fit-content;
        margin: 0 20px;

        @media only screen and (max-width: 1300px) {
            width: 100%;
            flex-direction: column;
        }

        .image {
            width: 150px;
            img {
                width: 100%;
            }
        }
        .info {
            width: calc(100% - 150px);
            display: flex;
            flex-direction: column;
            gap: 20px;
            .title {
                font-size: 1.2rem;
                font-weight: bold;
            }
            .more {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                .item {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    padding: 0 20px 0 0;
                    &.description {
                        width: 100%;
                        .desc {
                            width: 100%;
                        }
                    }
                    .desc {
                        font-weight: 500;
                        width: 150px;
                    }
                }
            }
        }
    }
</style>
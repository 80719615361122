<template>
    <template v-if="answers.length">
        <template v-for="(ans, i) in answers" :key="i">
            <article>
                <input type="text" v-model="answers[i].text" placeholder="Insert Answer Here">
                <span class="delete" @click="deleteAns(i)">x</span>
            </article>
        </template>
        <span class="addAns" @click="addAns">Add New Answer</span>
    </template>
    <template v-else>
        <div class="noAns">
            <span>You have no answers created yet.</span>
            <span class="addAns" @click="addAns">Create the first one here</span>
        </div>
    </template>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        },
        filled: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            answers: []
        }
    },

    emits: ['answers'],

    watch: {
        answers: {
            deep: true,
            handler(val) {
                this.$emit('answers', [this.item.name, val])
            }
        },
        filled: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val[this.item.name]) this.answers = val[this.item.name]
            }
        }
    },

    methods: {
        addAns() {
            this.answers.push({
                text: ''
            })
        },
        deleteAns(i) {
            this.answers.splice(i, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
    article {
        display: flex;
        align-items: center;
        position: relative;

        .delete {
            position: absolute;
            right: 0;
            user-select: none;
            cursor: pointer;
            width: 10px;
            height: 10px;
        }

        input {
            width: 100%;
            border-bottom: solid 1px grey;
            padding: 10px 20px 10px 10px;
        }
    }

    .noAns {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .addAns {
        background: var(--primary-color);
        color: white;
        width: fit-content;
        padding: 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            background: var(--primary-hover);
        }
    }
</style>
<template>
    <div class="create-btn" @click="create = true">
        <span>Create</span>
    </div>
    <form-listing :loaded="loaded" :entries="bodyItems" :headItems="headItems" :canCreate="true" :title="selType === 1 ? 'Open Question' : 'Close Question'" @optionSel="handleOption"/>
    <create-question v-if="create" :title="'Question'" :editInfo="editInfo" @close=";(create = false), (selItem = 0)"  @create="handleCreate"/>
    <see-modal v-if="see" :title="`Question Card`" :items="toSee" @close=";(see = false), (selItem = 0)" />
</template>

<script>
import FormListing from '@/components/List/Table/Head.vue'
import CreateQuestion from '@/components/FormListing/Create/NewIndex.vue'
import axiosInstance from '@/services/AxiosTokenInstance';
import SeeModal from "@/components/Ui/CRUD/See.vue";

export default {
    props: {
        type: String
    },

    data() {
        return {
            loaded: false,
            entries: [],
            create: false,
            headItems: ['Question', 'Nr of Answers', 'Categories'],
            bodyItems: [],
            editOptions: ["see", "edit"],
            hasOptions: true,
            selItem: 0,
            see: false,
            editInfo: {}
        }
    },

    emits:['close'],

    computed: {
        selType() {
            if (this.type === 'open-forms') return 1
            return 2
        },
        toSee() {
            const item = this.entries.find((el) => el.id === this.selItem) || {};
            if (Object.keys(item).length) {
                return [
                {
                    title: "Header",
                    image: null,
                    value: item.question,
                    id: item.id,
                },
                {
                    title: "Answers",
                    value: item.answers.map(el => {
                        return {name: el.answer}
                    }),
                },
                {
                    title: "Categories",
                    value: item.categories.map(el => el.category_details),
                },
                ];
            }
            return [];
        },
    },

    components: {
        FormListing,
        CreateQuestion,
        SeeModal
    },

    provide() {
        return {
            edit: this.editOptions,
            hasOptions: this.hasOptions,
        };
    },

    watch: {
        entries() {
            this.fillBodyItems();
        },
        type() {
            this.getData()
        },
        selItem(val) {
            if(!val) {
                this.editInfo = {}
                return
            }
        }
    },

    methods: {
        fillBodyItems() {
            this.bodyItems = this.entries.map((item) => {
                const categories = [];
                item.categories.map((el) => {
                    if (
                        el.category_details &&
                        !categories.some((site) => site.name === el.category_details.name)
                    )
                    categories.push(el.category_details);
                });

                return [
                    {
                        template: "normal",
                        id: item.id,
                        data: item.question || '',
                    },
                    {
                        template: "normal",
                        data: item.answers.length.toString() || '0',
                    },
                    {
                        template: "multiple",
                        data: categories,
                    },
                ];
            });
        },
        async getData() {
            this.loaded = false
            let orgData = JSON.parse(localStorage.getItem('organization'));

            const response = await axiosInstance.get(`category-question?org_id=${orgData.id}&question_type=${this.selType}`).finally(() => this.loaded = true)
            if(response.data.data) this.entries = response.data.data.data
        },

        async handleCreate(data) {
            this.create = false
            let orgData = JSON.parse(localStorage.getItem('organization'));

            const toSend = {
                org_id: orgData.id,
                question: data.question,
                question_type: this.selType,
                input_type: 'checkbox',
                answers: data.answers.filter(el => !el.id).map(el => {
                    return {answer: el.text}
                })
            }

            if (data.categories.new_items) toSend.category_ids = data.categories.new_items.map(el => el.id)

            if(data.question_id) {
                toSend.questionId = data.question_id
                toSend.delete_category_ids = data.categories.deleted_items?.map(el => el.rel_id)

                const delete_answers = data.answers ? this.editInfo.answers.filter(el => !data.answers.map(e => e.id).includes(el.id)).map(el => el.id) : []
                toSend.delete_answer_ids = delete_answers
            }

            const response = await axiosInstance.post('category-question', toSend)
            if (response.status === 200) {
                this.getData()
                return
            }
            this.emitter.emit('alert', 'Something went wrong!')
            this.selItem = 0
            this.editInfo = {}
        },

        async deteleQuestion() {
            await axiosInstance.delete(`category-question/${this.selItem}`).finally(() => {
                this.getData()
                this.selItem = 0
            })
        },

        handleOption(data) {
            this.selItem = data[1];
            switch (data[0]) {
                case "see":
                    this.see = true;
                break;
                case "edit":
                    this.editInfo = this.entries.filter(el => el.id === this.selItem)[0]
                    this.create = true
                break;
                case "delete":
                    this.deteleQuestion();
                break;
            }
        },
    },

    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.create-btn {
    position: absolute;
    padding: 10px 25px;
    top: 10px;
    right: 20px;
    background: var(--primary-color);
    color: white;
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
        background: var(--primary-hover);
    }
}
</style>
<template>
    <div class="create">
        <div class="item">
            <h2 class="title">Start Date</h2>
            <div class="fields">
                <input class="date" type="date" :min="firstCreated.split('T')[0]" :max="new Date().toISOString().split('T')[0]" v-model="data['start_date']">
                <input class="number" type="number" @input="handleHours($event, 'start_hour')" placeholder="Hours">
                <input class="number" type="number" @input="handleMinutes($event,'start_minute')" placeholder="Minutes">
            </div>
        </div>
        <div class="item">
            <h2 class="title">Finish Date</h2>
            <div class="fields">
                <input class="date" type="date" :min="data['start_date'] ? data['start_date'] : firstCreated.split('T')[0]" :max="new Date().toISOString().split('T')[0]" v-model="data['end_date']">
                <input class="number" type="number" @input="handleHours($event, 'end_hour')" placeholder="Hours">
                <input class="number" type="number" @input="handleMinutes($event,'end_minute')" placeholder="Hinutes">
            </div>
        </div>
        <div class="item">
            <h2 class="title">Select User</h2>
            <div class="fields">
                <Select v-if="users" :no-title="true" :item="users" @data="handleSelectData" />
            </div>
        </div>
        <div class="submit" @click="submitData">
            <button>Submit</button>
        </div>
    </div>
</template>

<script>
import api from "@/mixins/optionsApi";
import Select from '@/components/Create/Templates/Select.vue'
export default {
    props: {
        firstCreated: {
            type: String,
            default: () => ''
        }
    },

    mixins: [api],

    components: {
        Select
    },

    data() {
        return {
            data: {},
            users: undefined
        }
    },

    async created() {
        const users = await this.api('org_users')
        this.users = {
            label: 'user',
            name: 'user',
            options: users
        }
    },

    methods: {
        handleSelectData(data) {
            this.data.user = data[1]
        },

        submitData() {
            const keys = ["start_date","start_hour","start_minute","end_date","end_hour","end_minute","user"]
            const missing = keys.filter(item => Object.keys(this.data).indexOf(item) == -1)

            if (missing.length) {
                alert(`You are missing the following values: ${missing.join(', ')}`)
                return
            }
            
            const start_date = new Date(`${this.data.start_date}, ${this.data.start_hour.toString().padStart(2, '0')}:${this.data.start_minute.toString().padStart(2, '0')}`).toISOString()
            const end_date = new Date(`${this.data.end_date}, ${this.data.end_hour.toString().padStart(2, '0')}:${this.data.end_minute.toString().padStart(2, '0')}`).toISOString()
            const toSend = {
                start_date,
                end_date,
                user: this.data.user.id
            }

            this.$emit('create', toSend)
        },

        handleHours(e, field) {
            if (e.target.value < 0) e.target.value = 0
            if (e.target.value > 23) e.target.value = 23


            this.data[field] = e.target.value
        },

        handleMinutes(e, field) {
            if (e.target.value < 0) e.target.value = 0
            if (e.target.value > 59) e.target.value = 59

            this.data[field] = e.target.value
        }
    },
}
</script>

<style lang="scss" scoped>
    .create {
        padding: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        width: 100%;

        .item {
            margin-top: 16px;
            display: flex;
            flex-direction: column;

            &:first-of-type {
                margin-top: 0px;
            }

            h2 {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 8px;
                color: var(--primary-color);
            }

            .fields {
                width: 100%;
                display: flex;
                gap: 4px;

                input {
                    background: rgba($grey, 0.5);
                }

                .date {
                    width: 60%;
                    padding: 0 0 0 12px;

                    &::-webkit-calendar-picker-indicator {
                        cursor: pointer;
                        padding: 12px 12px 12px 60%;
                    }
                }

                .number {
                    width: 20%;
                    padding: 12px;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }

                .item {
                    width: 100%;
                }
            }
        }

        .submit {
            position: absolute;
            bottom: 20px;
            right: 20px;
            padding: 10px 20px;
            background: var(--primary-color);
            border-radius: 4px;

            button {
                background: unset;
                color: white;
                cursor: pointer;
                user-select: none;
            }
        }
    }
</style>
<template>
    <section>
        <main>
            <div class="tab">
                <div class="title">{{title}}</div>
                <div class="out" @click="$emit('close')">X</div>
            </div>
            <div class="bar">
                <span :style="{width: `calc(${(100 / steps.length)}% * ${isActive + 1})`}"></span>
            </div>
            <div class="form">
                <step :data="steps[isActive].items" :filledData="editInfo" @step="saveStep"/>
            </div>
            <div class="buttons" :class="{single: isActive === 0}">
                <span v-show="isActive > 0" @click="isActive -= 1">previous</span>
                <template v-if="isActive+1 === steps.length">
                    <span class="next" @click="$emit('create', toCreate)">
                        {{!Object.keys(editInfo).length ? 'create' : 'save changes'}}
                    </span>
                </template>
                <template v-else>
                    <span class="next" @click="isActive += 1">next</span>
                </template>
            </div>
        </main>
    </section>
</template>

<script>
import Step from './Step.vue'
import steps from './steps.json'
export default {

    props: {
        title: {
            type: String
        },
        editInfo: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    emits: ['create'],

    data() {
        return {
            isActive: 0,
            steps,
            toCreate: {}
        }
    },

    components: {
        Step
    },

    methods: {
        saveStep(val) {
            this.toCreate = val
        }
    },

}
</script>

<style lang="scss" scoped>
section {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(100,100,100,0.4);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    main {
        background: white;
        width: 95%;
        max-width: 550px;
        height: 100%;
        max-height: 800px;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        .tab {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
            box-shadow: 0px 1px 6px #e0e0e0;
            padding: 20px;

            div {
                font-weight: 600;
                font-size: 1.3rem;
            }

            .title {
                width: 100%;
                text-transform: uppercase;
            }

            .out {
                user-select: none;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    color: grey;
                }
            }
        }



        .bar {
            width: 100%;
            height: 2px;
            display: flex;
            span {
                height: 2px;
                background: var(--primary-color);
                transition: 0.2s;
            }
        }

        .form {
            height: calc(100% - 1.5rem);
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            padding: 20px;
            height: calc(100% - 80px);
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        .buttons {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            &.single {
                justify-content: flex-end;
            }
            span {
                min-width: 30%;
                max-width: 50%;
                display: flex;
                justify-content: center;
                padding: 10px 25px;
                background: $grey;
                color: white;
                border-radius: 2px;
                text-transform: capitalize;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    background: $grey-hover;
                }

                &.next {
                    background: var(--primary-color);
                    &:hover {
                        background: var(--primary-hover);
                    }
                }
            }
        }
    }

}
</style>
<template>
    <template v-for="(item, index) in data" :key="index">
        <Text v-if="item.template === 'text'" :item="item" :filled="finalData" @data="handleSelects" />
        <Answers v-else-if="item.template === 'answers'" :filled="finalData" :item="item" @answers="handleSelects"/>
        <multiple-select v-else-if="item.template === 'multiple-select'" :filled="finalData" :item="item" @data="handleSelects" />
    </template>
</template>
<script>
import Text from './Templates/Text.vue'
import Answers from './Templates/Answers.vue'
import MultipleSelect from './Templates/MultipleSelect.vue'
export default {
    props: {
        data: {
            type: Array
        },
        filledData: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    emits: ['step'],

    data() {
        return {
            finalData: {}
        }
    },
    components: {
        Text,
        MultipleSelect,
        Answers
    },
    watch: {
        finalData: {
            deep: true,
            handler(val) {
                this.$emit('step', val)
            }
        }
    },
    methods: {
        handleSelects(data) {
            this.finalData[data[0]] = data[1]
        },
    },

    created() {
        if(Object.keys(this.filledData).length) {
            this.finalData.question_id = this.filledData.id
            this.finalData.question = this.filledData.question
            this.finalData.categories = this.filledData.categories.map(el => {
                let toSend = el.category_details
                toSend.rel_id = el.id
                return toSend
            })
            this.finalData.answers = this.filledData.answers.map(el => {
                return {id: el.id,text: el.answer}
            })
        }
    }
}
</script>
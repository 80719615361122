<template>
    <section>
        <div class="content" v-click-outside="handleOutside">
            <header>
                <h2>Ticket Times</h2>
                <span v-if="[4, 6].includes(getUserProfile.role?.id) && !history" class="add" :class="{back: isCreating}" @click="isCreating = !isCreating">{{isCreating ? 'Close Create Form' : 'Add Timer Manually'}}</span>
                <span @click="handleOutside" class="close" />
            </header>
            <article v-if="data && !isCreating">
                <div class="quick-data">
                    <div class="column">
                        <figure>
                            <img src="/assets/images/timer.svg" alt="">
                        </figure>
                        <div class="info">
                            <span class="title">My Time</span>
                            <span class="value">{{ secondsToTime(data.user_total) }}</span>
                        </div>
                    </div>
                    <div class="column">
                        <figure>
                            <img src="/assets/images/timer.svg" alt="">
                        </figure>
                        <div class="info">
                            <span class="title">Total Time</span>
                            <span class="value">{{ secondsToTime(data.total) }}</span>
                        </div>
                    </div>
                </div>
                <div class="data">
                    <template v-for="(item, i) in data.data.filter(el => el.user_id)" :key="i">
                        <div class="row">
                            <div class="user">
                                <figure>
                                    <img v-if="item.user.avatar" :src="imgURL + item.user.avatar" alt="">
                                    <span v-else class="no-img">{{ item.user.name.charAt(0) }}</span>
                                </figure>
                                <span>{{ item.user.name }}</span>
                            </div>
                            <div class="time">
                                <span class="icon" :class="{ongoing :item.status}" />
                                <span v-if="!item.status">{{ secondsToTime(item.time) }}</span>
                                <Counter v-else :date="item.created_at" />
                            </div>
                            <div class="dates">
                                <div class="item">
                                    <h6 class="day" v-if="compareDays(item.created_at, item.updated_at)">{{ new Date(item.created_at).toLocaleDateString() }}</h6>
                                    <h6 class="day" v-else>{{ new Date(item.created_at).toLocaleDateString() }} - {{ new Date(item.updated_at).toLocaleDateString() }}</h6>
                                    <span v-if="!item.status">{{ convertToHours(item.created_at) }} - {{ convertToHours(item.updated_at) }}</span>
                                    <span v-else>ongoing</span>
                                </div>
                            </div>
                            
                        </div>
                    </template>
                </div>
            </article>
            <article v-else-if="isCreating">
                <ManualTimer :first-created="created" @create="handleCreateTimer" />
            </article>
        </div>
    </section>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
import Counter from '@/components/Ui/Ticket/Counter.vue'
import ManualTimer from '@/components/Ui/Ticket/ManualTimer.vue'
import { mapGetters } from 'vuex'
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
export default {
    props: {
        ticket: {
            type: Number,
            default: () => 0
        },
        created: {
            type: String,
            default: () => ''
        },
        history: {
            type: Boolean,
            default: () => false
        }
    },

    components: {
        Counter,
        ManualTimer
    },

    data() {
        return {
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            data: undefined,
            imgURL: process.env.VUE_APP_DO_SPACES,
            isCreating: false
        }
    },

    computed: {
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },

    created() {
        this.getTicketTimers()
    },

    methods: {
        async getTicketTimers() {
            const response = await axiosInstance.get(`ticket-timer/${this.ticket}?org_id=${this.org.id}`)
            if (response.status !== 200) {
                this.emitter.emit("alert", response.data.errors);
                this.$emit('close')
                return
            }

            this.data = response.data.data
        },

        secondsToTime(totalseconds) {
            const day = 86400;
            const hour = 3600;
            const minute = 60;

            const days = Math.floor(totalseconds / day);
            const hours = Math.floor((totalseconds - days * day)/hour);
            const minutes = Math.floor((totalseconds - days * day - hours * hour)/minute);
            const seconds = totalseconds - days * day - hours * hour - minutes * minute;

            if (days) {
                return `${days} ${days === 1 ? 'day' : 'days'}, ${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
            }

            if (hours) {
                return `${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
            }

            if (minutes) {
                return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}, ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`
            }

            return `${seconds} seconds`
        },

        compareDays(first, second) {
            return new Date(first).toLocaleDateString() === new Date(second).toLocaleDateString()
        },

        convertToHours(item) {
            const date = new Date(item)
            return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds().toString().padStart(2, '0')}`
        },

        handleOutside() {
            this.$emit('close')
        },

        async handleCreateTimer(data) {
            const response = await axiosInstance.put(`manual-ticket-timer/${this.ticket}?org_id=${this.org.id}`, data)
            if (response.status !== 200) {
                this.emitter.emit("alert", response.data.errors);
                return
            }
            this.isCreating = false
            this.getTicketTimers()
        }
    }
}
</script>

<style lang="scss" scoped>
    section {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.2);
        backdrop-filter: blur(2px);
        z-index: 150;
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            width: 95%;
            max-width: 750px;
            height: 95%;
            max-height: 600px;
            background: white;
            border-radius: 4px;
            

            header {
                height: 4rem;
                padding: 0 70px 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 1px $grey;
                position: relative;

                h2 {
                    margin: 0;
                    font-weight: 600;
                    font-size: 20px;
                }

                .add {
                    background: var(--primary-color);
                    padding: 10px;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                    user-select: none;

                    &.back {
                        background: $red;
                    }
                }
                
                .close {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 4rem;
                    width: 4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &::before, &::after {
                        content: '';
                        width: 1rem;
                        height: 2px;
                        background: black;
                        position: absolute;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }

            article {
                display: flex;
                flex-direction: column;
                height: calc(100% - 4rem);

                .quick-data {
                    width: 100%;
                    display: flex;
                    border-bottom: solid 1px $grey;

                    .column {
                        height: 4rem;
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // padding: 20px;
                        background: rgba($grey, 0.5);
                        
                        &:first-of-type {
                            border-right: solid 1px $grey;
                        }

                        figure {
                            width: 30px;
                            height: 30px;

                            img {
                                width: 100%;
                            }
                        }

                        .info {
                            margin-left: 8px;
                            display: flex;
                            flex-direction: column;

                            .title {
                                font-size: 10px;
                                // font-weight: 600;
                            }

                            .value {
                                color: var(--primary-color);
                                font-weight: 600;
                            }
                        }
                    }
                }

                .data {
                    overflow: hidden auto;
                    height: calc(100% - 4rem);

                    .row {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding: 20px;
                        border-bottom: solid 1px $grey;

                        div {
                            width: 33.333%;
                            display: flex;
                            align-items: center;
                        }

                        .user {
                            figure {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            span {
                                margin-left: 8px;
                            }
                        }

                        .time {
                            justify-content: center;
                            .icon {
                                width: 8px;
                                height: 8px;
                                margin-right: 4px;
                                background: grey;

                                &.ongoing {
                                    background: $red;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .dates {
                            justify-content: flex-end;

                            .item {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                align-items: flex-end;

                                h6 {
                                    font-weight: 500;
                                }

                                span {
                                    display: flex;
                                    font-weight: 600;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="content-title">
        <h3 class="title">Ticket Types</h3>
        <div class="create-btn" @click="create = true">
            <span>Create</span>
        </div>
    </div>
    <form-listing :loaded="loaded" :entries="bodyItems" :headItems="headItems" :canCreate="true" :title="'Ticket Type'" @optionSel="handleOption"/>
    <Pagination
        :entries="entries"
        :currentPage="currentPage"
        :total="total"
        :perPage="perPage"
        :pages="pages"
        @goToFirst="currentPage = 1"
        @goToLast="currentPage = pages"
        @goToPage="handlePage"
        @changePage="handlePageChange"
    />
    <Create v-if="create"
        :items="toCreate"
        :response="response"
        @close=";(create = false), (selItem = 0)" 
        @data="handleCreate"
    />
    <Edit
        v-if="editItem"
        :response="response"
        :title="`Edit Type`"
        :items="toEdit"
        @close=";(editItem = false), (response = [])"
        @submitData="editData"
    />
</template>

<script>
import Pagination from "@/components/List/Pagination.vue";
import FormListing from '@/components/List/Table/Head.vue'
import Create from "@/components/Create/Index.vue";
import Edit from "@/components/Ui/CRUD/Edit.vue";
import axiosInstance from '@/services/AxiosTokenInstance';
import api from "@/mixins/optionsApi";

export default {
    props: {
        type: String
    },

    data() {
        return {
            loaded: false,
            entries: [],
            create: false,
            headItems: ['Typology', 'Parent'],
            bodyItems: [],
            editOptions: ["edit"],
            hasOptions: true,
            selItem: 0,
            editItem: false,
            editInfo: {},
            response: [],
            parents: this.api("parent-types"),
            perPage: 0,
            total: 0,
            pages: 0,
            currentPage: 1,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        }
    },

    mixins: [api],

    emits:['close'],

    computed: {
        toCreate() {
            return [
                {
                    type: "text",
                    name: "title",
                    label: "Title",
                    required: true,
                },
                {
                    type: "boolean",
                    name: "hasParent",
                    label: "Has a Parent?",
                    required: false,
                    extra: [
                        {
                            type: "select",
                            name: "parent",
                            label: "Parent",
                            options: this.parents,
                            required: true,
                        }
                    ],
                },
            ];
        },
        toEdit() {
            const item = this.entries.find(el => el.id === this.selItem) || {}
            
            return [
                {
                    type: "text",
                    name: "title",
                    label: "Title",
                    required: true,
                    value: item.name,
                },
                {
                    type: "boolean",
                    name: "hasParent",
                    label: "Has a Parent?",
                    value: item.parent ? true : false,
                    required: false,
                    extra: [
                        {
                            type: "select",
                            name: "parent",
                            label: "Parent",
                            options: this.parents,
                            value: item.parent,
                            required: true,
                        }
                    ],
                },
            ];
        },
    },
    

    components: {
        FormListing,
        Create,
        Edit,
        Pagination
    },

    provide() {
        return {
            edit: this.editOptions,
            hasOptions: this.hasOptions,
        };
    },

    watch: {
        currentPage: {
            immediate: true,
            handler() {
                this.getData();
            },
        },
        entries() {
            this.fillBodyItems();
        },
        type() {
            this.getData()
        },
        selItem(val) {
            if(!val) {
                this.editInfo = {}
                return
            }
        }
    },

    methods: {
        handlePageChange(to) {
            switch (to) {
                case "less":
                this.currentPage -= 1;
                break;
                case "more":
                this.currentPage += 1;
                break;
            }
        },

        handlePage(to) {
            this.currentPage = to
        },

        fillBodyItems() {
            this.bodyItems = this.entries.map((item) => {
                return [
                    {
                        template: "normal",
                        id: item.id,
                        data: item.name || '',
                    },
                    {
                        template: "normal",
                        data: item.parent?.name || '-',
                    }
                ];
            });
        },
        async getData() {
            this.loaded = false
            this.perPage = 0
            this.total = 0
            this.pages = 0
            this.entries = []

            const response = await axiosInstance.get(`typology?org_id=${this.org.id}&page=${this.currentPage}`).finally(() => this.loaded = true)
            if(response.data.data) {
                this.perPage = response.data.data.per_page;
                this.total = response.data.data.total;
                this.pages = response.data.data.last_page;
                this.entries = response.data.data.data
            }

            this.parents = await this.api("parent-types")
        },

        async handleCreate(data) {
            const orgData = JSON.parse(localStorage.getItem('organization'));

            const toSend = {
                name: data?.title,
                organization_id: orgData.id
            }

            if (data.hasParent && data.hasParent.hasParent) toSend.parent_id = data.hasParent.parent.id

            const response = await axiosInstance.post('typology', toSend)

            if (response.status === 200) {
                this.response = [true, true]
                this.getData()
            }
            else {
                this.response = [true, false]
            }
        },

        async editData(data) {
            const orgData = JSON.parse(localStorage.getItem('organization'));
            const toSend = {
                name: data?.Title,
                organization_id: orgData.id,
                typology_id: this.selItem
            }
            if (!data['Has a Parent?']) toSend.parent_id = '0'
            else if(data['Has a Parent?'] && data.parent) {
                toSend.parent_id = data.parent.id.toString()
            }
            const response = await axiosInstance.post('typology', toSend)
            
            if (response.status === 200) {
                this.response = [true, true]
                this.getData()
            }
            else {
                this.response = [true, false]
                this.emitter.emit('alert', response.data.message)
            }
        },

        async deteleType() {
            const response = await axiosInstance.delete(`typology/${this.selItem}`).finally(() => {
                this.getData()
            })

            if (response.status !== 200) {
                this.emitter.emit('alert', response.data.message)
            }
        },

        handleOption(data) {
            this.selItem = data[1];
            switch (data[0]) {
                case "edit":
                    this.editItem = true
                break;
                case "delete":
                    this.deteleType();
                break;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.create-btn {
    position: absolute;
    padding: 10px 25px;
    top: 0px;
    right: 20px;
    background: var(--primary-color);
    color: white;
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
        background: var(--primary-hover);
    }
}

.content-title {
    position: relative;
}

.pagination {
    margin-top: 20px;
}
</style>
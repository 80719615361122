<template>
    <div class="details-container">
        <main>
            <div class="title">{{details.title}}</div>
            <div class="info">
                <div class="item" v-if="details.ticket_typology.length">
                    <div class="subtitle">Problem Type</div>
                    <span>{{ details.ticket_typology.map(el => el.typology.name).join(', ') }}</span>
                </div>
                <div class="item" v-if="details.ticket_assistance_type.filter(el => el.assistance_details).length">
                    <div class="subtitle">Assistance Type</div>
                    <template v-for="(assistance_type, i) in details.ticket_assistance_type.filter(el => el.assistance_details)" :key="i">
                        <span class="text" v-if="assistance_type.assistance_details">{{assistance_type.assistance_details.name}}</span>
                    </template>
                </div>
                <div class="item">
                    <div class="subtitle">Was defined as chargeable?</div>
                    <span>{{details.is_chargeable === 2 ? 'No' : 'Yes'}}</span>
                </div>
            </div>
            <div class="notes" v-if="details.notes">
                <div class="item">
                    <div class="subtitle">Closing Notes</div>
                    <span class="text">{{details.notes}}</span>
                </div>
            </div>
            <div class="documents" v-if="details.ticket_documents.length">
                <div class="item">
                    <div class="subtitle">Ticket Documents</div>
                    <template v-for="(document, i) in details.ticket_documents" :key="i">
                        <article @click="openFile(document.doc_file)">
                            <figure>
                                <img src="/assets/images/doc-icon.svg" alt="doc" />
                            </figure>
                            <span class="text">{{document.doc_name || 'File'}} </span>
                        </article>
                    </template>
                </div>
            </div>
            <div class="ticket-timers">
                <div class="check-timers">
                    <div class="item" @click="showTimers = true">
                        <figure>
                            <img src="/assets/images/timer.svg" alt="">
                        </figure>
                        <span>Check Timers</span>
                    </div>
                </div>
                <div class="item">
                    <div class="subtitle">Ticket Timers</div>
                    <article>
                        <TicketTimes :item="{id: details.id, created_date: details.created_at, closed_date: details.updated_at, times}" />
                        <div class="stamps" v-if="Object.keys(usersData).length">
                            <header>
                                <span>User</span>
                                <span>Time Appointed</span>
                            </header>
                            <template v-for="(user, i) in Object.keys(usersData)" :key="i">
                                <div class="data">
                                    <div class="user">
                                        <figure>
                                            <img v-if="usersData[user][0].user && usersData[user][0].user.avatar" :src="imgURL + usersData[user][0].user.avatar" alt="user_picture">
                                            <span v-else-if="!usersData[user][0].user"></span>
                                            <span v-else>{{ usersData[user][0].user.name.charAt(0) }}</span>
                                        </figure>
                                        <span class="name">{{ usersData[user][0].user ? usersData[user][0].user.name : 'undefined' }}</span>
                                    </div>
                                    <span class="time">{{ computeform(usersData[user][0].time) }}</span>
                                </div>
                            </template>
                        </div>
                    </article>
                </div>
            </div>
        </main>
        <TimesPopup v-if="showTimers" :history="true" :created="details.created_at" :ticket="details.id" @close="showTimers = false" />
    </div>
</template>

<script>
import TimesPopup from '@/components/Ui/Ticket/TimesPopup.vue'
import TicketTimes from '@/components/Ui/Ticket/HistoryTimers.vue'
import axiosInstance from "@/services/AxiosTokenInstance";
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  components: {
    TicketTimes,
    TimesPopup
  },

  data() {
    return {
        imgURL: process.env.VUE_APP_DO_SPACES,
        times: undefined,
        org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        usersData: {},
        showTimers: false
    }
  },

  created() {
    this.getTicketTimers()
  },

  methods: {
    openFile(path) {
        window.open(`${this.imgURL}${path}`, '_blank')
    },

    async getTicketTimers() {
        const response = await axiosInstance.get(`ticket-timer/${this.details.id}?org_id=${this.org.id}`)

        this.times = response.data.data

        this.filterUsers()
    },

    computeform(totalseconds) {
        const day = 86400;
        const hour = 3600;
        const minute = 60;

        const days = Math.floor(totalseconds / day);
        const hours = Math.floor((totalseconds - days * day)/hour);
        const minutes = Math.floor((totalseconds - days * day - hours * hour)/minute);
        const seconds = totalseconds - days * day - hours * hour - minutes * minute;

        if (days) {
            return `${days} ${days === 1 ? 'day' : 'days'}, ${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
        }

        if (hours) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
        }

        if (minutes) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}, ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`
        }

        return `${seconds} seconds`
        
    },

    filterUsers() {
        this.usersData = this.times.data.reduce((group, timer) => {
            const { user_id } = timer;
            
            group[user_id] = group[user_id] ?? [];

            if (group[user_id].length) {
                group[user_id][0].time += timer.time
            }
            else {
                group[user_id].push({user: timer.user, time: timer.time});
            }

            return group;
        }, {});
    }
  }
}
</script>

<style lang="scss" scoped>
.details-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: auto;
    height: 100%;
}

.details-container main{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.details-container main .title{
    font-weight: bold;
    font-size: 1.3rem;
}

.details-container main .info
{
    display: flex;
    gap: 60px;
}
.details-container main .info .item{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.details-container .subtitle {
    font-weight: bold;
}

.details-container .description{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info, .notes, .documents, .ticket-timers {
    padding: 20px;
    background: rgba($grey, 0.5);
    border-radius: 4px;
    display: flex;
    width: 100%;
}

.notes {
    .item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    
}

.documents {
    display: flex;
    flex-direction: column;
    
    article {
        display: flex;
        align-items: center;
        margin-top: 8px;
        cursor: pointer;
        user-select: none;

        span {
            margin-left: 8px;
            color: var(--primary-color);
            font-weight: 500;
            font-size: 12px;
        }
    }
}

.ticket-timers {
    position: relative;

    .check-timers {
        position: absolute;
        
        display: flex;
        justify-content: flex-end;
        top: 8px;
        right: 20px;

        .item {
            position: sticky;
            display: flex;
            align-items: center;
            padding: 10px;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;
            transition: 0.2s;

            &:hover {
                background: rgba($grey, 0.5);
            }

            figure {
                width: 16px;
                height: 16px;

                img {
                    width: 100%;
                }
            }

            span {
                margin-left: 8px;
            }
        }
    }
    .item {
        width: 100%;

        article {
            margin-top: 24px;
        }

        .stamps {
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            header {
                width: 100%;
                display: flex;
                border: solid 1px $grey;

                span {
                    width: 50%;
                    background: rgba($grey, 0.5);
                    text-align: center;
                    padding: 8px;
                    font-weight: 600;
                    font-size: 12px;

                    &:first-of-type {
                        border-right: solid 1px $grey;
                    }
                }
            }

            .data {
                display: flex;
                align-items: center;
                border: solid 1px $grey;
                border-top: unset;

                .user {
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    border-right: solid 1px $grey;

                    figure {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        span {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            background: var(--primary-color);
                            font-weight: 500;
                        }
                    }

                    .name {
                        margin-left: 8px;
                        font-weight: 500;
                    }
                }

                .time {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    font-weight: 600;
                    padding: 12px;
                }

                
            }
        }
    }
}
</style>
<template>
    <section class="navigation">
        <template v-for="(item, index) in data" :key="index">
            <span class="item" :class="{active: isActive === index}" @click="isActive = index">{{item.name}}</span>
        </template>
    </section>
</template>

<script>
import data from './nav.json'
export default {
    data() {
        return {
            data,
            isActive: 0,
            selected: {}
        }
    },

    watch: {
        isActive: {
            immediate: true,
            handler(val) {
                this.$emit('selected', data[val])
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .navigation {
        display: flex;
        flex-direction: column;
        width: 15%;
        .item {
            width: 100%;
            padding: 1rem;
            text-transform: capitalize;
            cursor: pointer;
            user-select: none;
            &:hover {
                background: linear-gradient(to right, #0066ffc5 0%, #0066ffc5 1.5%, #0066ff21 1.5%, #0066ff21 100%);
            }
            &.active {
                background: linear-gradient(to right, #0066FF 0%, #0066FF 1.5%, #0066ff3b 1.5%, #0066ff3b 100%);
                color: #0066FF;
                font-weight: bold;
            }
        }
    }
</style>
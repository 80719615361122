<template>
    <div class="item">
        <label for="">{{item.label}}</label>
        <input type="text" v-model="text" :placeholder="`Insert ${item.label} here`">
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        },
        filled: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    data() {
        return {
            text: this.filled[this.item.name] || '',
        }
    },

    watch: {
        text(val) {
            this.$emit('data', [this.item.name, val])
        },

        filled: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val[this.item.label]) this.text = val[this.item.label]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    label {
        font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
    }
    input {
        border: solid 1px #cad6e2;
        border-radius: 2px;
        padding: 10px 15px;
    }

    .item {
        display: flex;
        flex-direction: column;
    }
</style>
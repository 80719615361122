<template>
    <span>{{secondsToTime(count)}}</span>
</template>

<script>
export default {
    props: {
        date: {
            type: Date,
            required: true
        }
    },

    computed: {
        count() {
            return Math.floor((new Date().getTime() - new Date(this.date).getTime()) / 1000)
        }
    },

    methods: {
        secondsToTime(totalseconds) {
            const day = 86400;
            const hour = 3600;
            const minute = 60;

            const days = Math.floor(totalseconds / day);
            const hours = Math.floor((totalseconds - days * day)/hour);
            const minutes = Math.floor((totalseconds - days * day - hours * hour)/minute);
            const seconds = totalseconds - days * day - hours * hour - minutes * minute;

            if (days) {
                return `${days} ${days === 1 ? 'day' : 'days'}, ${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
            }

            if (hours) {
                return `${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
            }

            if (minutes) {
                return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}, ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`
            }

            return `${seconds} seconds`
        },
    }
}
</script>
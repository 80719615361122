<template>
    <article>
        <div class="quick-data" v-if="item.times">
            <div class="working-days">
                <h2>Days opened</h2>
                <div class="columns">
                    <div class="column">
                        <figure>
                            <img src="/assets/images/timer.svg" alt="">
                        </figure>
                        <div class="info">
                            <span class="title">Working days <small>({{ new Date(item.created_date).toLocaleDateString() }} - {{ new Date(item.closed_date).toLocaleDateString() }})</small></span>
                            <span class="value">{{ laborDays }} days</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hours-counted">
                <h2>Hours appointed</h2>
                <div class="columns">
                    <div class="column">
                        <figure>
                            <img src="/assets/images/timer.svg" alt="">
                        </figure>
                        <div class="info">
                            <span class="title">My Time</span>
                            <span class="value">{{ secondsToTime(item.times.user_total) }}</span>
                        </div>
                    </div>
                    <div class="column">
                        <figure>
                            <img src="/assets/images/timer.svg" alt="">
                        </figure>
                        <div class="info">
                            <span class="title">Total Time</span>
                            <span class="value">{{ secondsToTime(item.times.total) }}</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </article>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            data: undefined
        }
    },

    computed: {
        laborDays() {
            const startDate = new Date(this.item.created_date)
            const endDate = this.item.closed_date ? new Date(this.item.closed_date) : new Date()

            return this.getDaysOfWeekBetweenDates(startDate, endDate).length
        },
    },

    methods: {

        secondsToTime(totalseconds) {
            const day = 86400;
            const hour = 3600;
            const minute = 60;

            const days = Math.floor(totalseconds / day);
            const hours = Math.floor((totalseconds - days * day)/hour);
            const minutes = Math.floor((totalseconds - days * day - hours * hour)/minute);
            const seconds = totalseconds - days * day - hours * hour - minutes * minute;

            if (days) {
                return `${days} ${days === 1 ? 'day' : 'days'}, ${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
            }

            if (hours) {
                return `${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
            }

            if (minutes) {
                return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}, ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`
            }

            return `${seconds} seconds`
        },

        getDaysOfWeekBetweenDates (sDate, eDate) {
            const startDate = new Date(sDate)
            const endDate = new Date(eDate);
            
            endDate.setDate(endDate.getDate() + 1);
            
            const daysOfWeek = [];
            
            while (startDate < endDate) {
                const day = startDate.getDay()
                if (day && day !== 6) {
                    daysOfWeek.push(startDate.getDay());
                }
                
                startDate.setDate(startDate.getDate() + 1);
            }

            return daysOfWeek;
        }
    }
}
</script>

<style lang="scss" scoped>
    article {
        display: flex;
        flex-direction: column;
        height: calc(100% - 4rem);

        .quick-data {
            width: 100%;
            display: flex;
            border: solid 1px $grey;

            .working-days, .hours-counted {
                h2 {
                    font-weight: 600;
                    font-size: 12px;
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    padding: 4px 0;
                    background: rgba($grey, 0.5);
                    border-bottom: solid 1px $grey;
                }
            }

            .working-days {
                width: 40%;
                border-right: solid 1px $grey;

                .column {
                    width: 100%;
                    .info {
                        .title {
                            small {
                                font-weight: 500;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            .hours-counted {
                width: 60%;
                display: flex;
                flex-direction: column;

                .columns {
                    display: flex;

                    .column {
                        width: 50%;
                    }
                }
            }

            .column {
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: solid 1px $grey;
                
                &:last-of-type {
                    border-right: none;
                }

                figure {
                    width: 30px;
                    height: 30px;

                    img {
                        width: 100%;
                    }
                }

                .info {
                    margin-left: 8px;
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 10px;
                        // font-weight: 600;
                    }

                    .value {
                        color: var(--primary-color);
                        font-weight: 600;
                    }
                }
            }
        }
    }
</style>